import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React, { useState, ReactNode } from "react";

import { useAppSelector } from "../../app/hooks";
import { selectResources } from "../../app/reducers/resource";
import { Resource, DataSource } from "../../app/types";

interface CreateDataProps {
  allowSelection?: boolean;
  onComplete: (source: DataSource) => void;
}

export default function CreateData(props: CreateDataProps) {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [select, setSelect] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isRemote, setIsRemote] = useState(false);
  const [name, setName] = useState("");
  const [remoteUrl, setRemoteUrl] = useState("s3://bucket/path/to/file.csv");
  const [regression, setRegression] = useState(false);
  const [headers, setHeaders] = useState(false);
  const [sampleIds, setSampleIds] = useState(false);

  const resources = useAppSelector(selectResources).filter(
    (res: Resource) => res.type === "DATA_SOURCE"
  );

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    setFile(file);
    setName(file.name);
  }

  function handleSelectChange(e: SelectChangeEvent, child?: ReactNode) {
    setSelectedId(e.target.value);
  }

  function onContinue() {
    if (select) {
      return props.onComplete(Number(selectedId));
    }

    const source: DataSource = {
      file: file,
      remote_url: remoteUrl,
      headers: headers,
      sample_ids: sampleIds,
      regression: regression,
      name: name,
    };
    return props.onComplete(source);
  }

  const menuItems = resources.map((res: Resource) => (
    <MenuItem key={res.id} value={res.id}>{`${res.id}: ${res.name}`}</MenuItem>
  ));

  function selector() {
    return (
      <div>
        <FormControl fullWidth size="small">
          <InputLabel>Data Source</InputLabel>
          <Select
            value={selectedId}
            label="data-source"
            onChange={handleSelectChange}
          >
            {menuItems}
          </Select>
        </FormControl>
      </div>
    );
  }

  function uploader() {
    return (
      <div style={{ marginTop: "16px" }}>
        <Button
          variant="contained"
          component="label"
          sx={{ marginRight: "8px" }}
        >
          Upload File
          <input hidden type="file" accept=".csv" onChange={handleFileChange} />
        </Button>
        <span>{file ? file.name : "No file selected..."}</span>
      </div>
    );
  }

  function options() {
    return (
      <Stack>
        <FormControlLabel
          value={regression}
          onChange={(_, checked) => setRegression(checked)}
          control={<Checkbox value={regression} />}
          label="Regression"
        />
        <FormControlLabel
          value={headers}
          onChange={(_, checked) => setHeaders(checked)}
          control={<Checkbox value={headers} />}
          label="Headers"
        />
        <FormControlLabel
          value={sampleIds}
          onChange={(_, checked) => setSampleIds(checked)}
          control={<Checkbox value={sampleIds} />}
          label="Sample IDs"
        />
        <FormControlLabel
          value={isRemote}
          onChange={(_, checked) => setIsRemote(checked)}
          control={<Switch />}
          label="Remote data source"
        />
        <TextField
          size="small"
          id="name"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ marginTop: "8px" }}
        />
        {isRemote && (
          <TextField
            size="small"
            id="remote-url"
            label="Remote Url"
            value={remoteUrl}
            onChange={(e) => setRemoteUrl(e.target.value)}
            sx={{ marginTop: "16px" }}
          />
        )}
        {!isRemote && uploader()}
      </Stack>
    );
  }

  return (
    <div>
      {props.allowSelection && (
        <Button
          fullWidth
          variant="contained"
          endIcon={<SwapHorizIcon />}
          onClick={() => setSelect(!select)}
          sx={{ marginBottom: "12px" }}
        >
          {select ? "Create new source" : "Select existing source"}
        </Button>
      )}
      {select ? selector() : options()}
      <Button
        fullWidth
        variant="contained"
        color="success"
        onClick={onContinue}
        sx={{ marginTop: "12px" }}
      >
        Continue
      </Button>
    </div>
  );
}
