import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export type Action =
  | "none"
  | "confirm"
  | "upload"
  | "train"
  | "predict"
  | "kfold";

interface SelectActionProps {
  onActionSelected: (action: Action) => void;
}

export default function SelectAction(props: SelectActionProps) {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" align="center">
        I want to...
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.onActionSelected("upload")}
      >
        Upload a data source
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => props.onActionSelected("train")}
      >
        Train a model
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => props.onActionSelected("predict")}
      >
        Create a prediction
      </Button>
      <Button
        variant="contained"
        color="warning"
        onClick={() => props.onActionSelected("kfold")}
      >
        Run a k-fold
      </Button>
    </Stack>
  );
}
