import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

import { useAppSelector } from "../../app/hooks";
import { selectResources } from "../../app/reducers/resource";
import {
  Recipe,
  ResourceType,
  DataSource,
  KFold,
  Model,
  Prediction,
} from "../../app/types";

interface ConfirmProps {
  recipe: Recipe;
  onSubmit: () => void;
}

type Renderable = DataSource | KFold | Model | Prediction;

export default function Confirm(props: ConfirmProps) {
  const resources = useAppSelector(selectResources);

  function renderResource(resource: Renderable | undefined, header: string) {
    if (!resource) {
      return undefined;
    }

    let body;
    if (typeof resource === "number") {
      const res = resources.find((r) => r.id === resource);
      body = `#${resource}: ${res?.name}`;
    } else {
      body = JSON.stringify(resource, null, 2);
    }

    return (
      <div>
        <Typography variant="h5">{header}</Typography>
        <pre>{body}</pre>
      </div>
    );
  }

  return (
    <Stack spacing={2}>
      {renderResource(props.recipe.data_source, "Data Source")}
      {renderResource(props.recipe.k_fold, "K Fold")}
      {renderResource(props.recipe.model, "Model")}
      <Button
        fullWidth
        variant="contained"
        color="success"
        onClick={props.onSubmit}
      >
        Create Resources
      </Button>
    </Stack>
  );
}
