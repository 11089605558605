import AddIcon from "@mui/icons-material/Add";
import { StyledEngineProvider } from "@mui/material";
import Fab from "@mui/material/Fab";
import React, { useState } from "react";

import styles from "./Home.module.css";
import NavBar from "../../components/NavBar";
import ResourceCreator from "../../components/ResourceCreator";
import ResourceTable from "../../components/ResourceTable";

export default function Home() {
  const [showCreator, setShowCreator] = useState(false);

  function onCreatorClose() {
    setShowCreator(false);
  }

  return (
    <div className={styles.homeContainer}>
      <NavBar />
      <StyledEngineProvider injectFirst>
        <Fab
          color="primary"
          className={styles.newButton}
          onClick={() => setShowCreator(true)}
        >
          <AddIcon />
        </Fab>
      </StyledEngineProvider>
      <div className={styles.dataContainer}>
        <ResourceTable />
      </div>
      <ResourceCreator open={showCreator} onClose={onCreatorClose} />
    </div>
  );
}
