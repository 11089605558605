import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { ChangeEvent } from "react";

import ThemeSwitch from "./ThemeSwitch";
import { logout } from "../api/auth";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setAuthStatus } from "../app/reducers/auth";
import { selectTheme, asyncToggleTheme } from "../app/reducers/theme";
import VerseonDark from "../res/logo-inline-dark.svg";

export default function NavBar() {
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector(selectTheme) === "dark";

  function onThemeChange(e: ChangeEvent<HTMLInputElement>, checked: boolean) {
    dispatch(asyncToggleTheme());
  }

  function onLogout() {
    logout().then((success) => {
      if (success) {
        dispatch(setAuthStatus("no"));
      }
    });
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={VerseonDark} style={{ height: "32px", width: "auto" }} />
          <div style={{ flexGrow: 1 }} />
          <ThemeSwitch checked={darkMode} onChange={onThemeChange} />
          <Button color="inherit" onClick={onLogout} sx={{ marginLeft: "4px" }}>
            Logout
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
