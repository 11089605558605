import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";

import { KFold } from "../../app/types";

interface CreateKFoldProps {
  onComplete: (kFold: KFold) => void;
}

export default function CreateKFold(props: CreateKFoldProps) {
  const [k, setK] = useState(5);
  const [stratified, setStratified] = useState(false);

  function onContinue() {
    const kFold: KFold = {
      k: k,
      stratified: stratified,
    };
    return props.onComplete(kFold);
  }

  return (
    <Stack spacing={2}>
      <FormControlLabel
        value={stratified}
        onChange={(_, checked) => setStratified(checked)}
        control={<Checkbox value={stratified} />}
        label="Stratified"
      />
      <Select
        label="K"
        value={k}
        onChange={(e) => setK(Number(e.target.value))}
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
      </Select>
      <Button
        fullWidth
        variant="contained"
        color="success"
        onClick={onContinue}
        sx={{ marginTop: "12px" }}
      >
        Continue
      </Button>
    </Stack>
  );
}
