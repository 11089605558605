import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import styles from "./Login.module.css";
import { login } from "../../api/auth";
import { useAppDispatch } from "../../app/hooks";
import { setAuthStatus } from "../../app/reducers/auth";
import { loadResourcesAsync } from "../../app/reducers/resource";
import VerseonDark from "../../res/logo-inline-dark.svg";
import VerseonLight from "../../res/logo-inline-light.svg";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userError, setUserError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [shakeProps, setShakeProps] = useSpring(() => ({
    transform: "translateX(0)",
  }));

  const dispatch = useAppDispatch();
  const theme = useTheme().palette.mode;
  const imgSrc = theme === "dark" ? VerseonDark : VerseonLight;

  function updateShakeProps() {
    setShakeProps({
      to: [
        { transform: "translateX(-10px)" },
        { transform: "translateX(10px)" },
        { transform: "translateX(-10px)" },
        { transform: "translateX(10px)" },
        { transform: "translateX(-10px)" },
        { transform: "translateX(0)" },
      ],
      config: {
        duration: 75,
      },
    });
  }

  function onLogin() {
    let error = false;
    if (username === "") {
      setUserError(true);
      error = true;
    }

    if (password === "") {
      setPassError(true);
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);
    login(username, password)
      .then((status) => {
        if (status === "no") {
          updateShakeProps();
          return setAuthError(true);
        }
        dispatch(setAuthStatus(status));
        dispatch(loadResourcesAsync());
      })
      .finally(() => setLoading(false));
  }

  const AnimatedTextField = animated(TextField);
  return (
    <div className={styles.loginContainer}>
      <Paper elevation={8} className={`${styles.loginModule}`}>
        <img src={imgSrc} className={styles.logo} />
        <Stack spacing={2}>
          <AnimatedTextField
            fullWidth
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => {
              setUserError(false);
              setUsername(e.target.value);
            }}
            error={userError}
            style={shakeProps}
          />

          <AnimatedTextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassError(false);
              setPassword(e.target.value);
            }}
            error={passError}
            style={shakeProps}
          />
          <LoadingButton
            loading={loading}
            fullWidth
            variant="contained"
            onClick={onLogin}
          >
            Login
          </LoadingButton>
        </Stack>
        <Snackbar
          open={authError}
          autoHideDuration={2500}
          onClose={() => setAuthError(false)}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert
            onClose={() => setAuthError(false)}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Invalid credentials
          </Alert>
        </Snackbar>
      </Paper>
    </div>
  );
}
