import { AuthStatus } from "../app/types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export async function login(
  username: string,
  password: string
): Promise<AuthStatus> {
  const url = `${baseUrl}/v1/auth/authenticate`;
  try {
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ user_key: username, password: password }),
      credentials: "include",
      headers: { "content-type": "application/json" },
    });
    const valid = res.status === 200;
    return Promise.resolve(valid ? "yes" : "no");
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function verify(): Promise<AuthStatus> {
  const url = `${baseUrl}/v1/auth/verify`;
  try {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const valid = res.status === 200;
    return Promise.resolve(valid ? "yes" : "no");
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function logout(): Promise<boolean> {
  const url = `${baseUrl}/v1/auth/destroy`;
  try {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
    });
    const success = res.status === 200;
    return Promise.resolve(success);
  } catch (err) {
    return Promise.reject(err);
  }
}
