import React, { useState } from "react";

import CenterModal from "./CenterModal";
import Confirm from "./steps/Confirm";
import CreateData from "./steps/CreateData";
import CreateKFold from "./steps/CreateKFold";
import CreateModel from "./steps/CreateModel";
import SelectAction, { Action } from "./steps/SelectAction";
import { createResource } from "../api/resource";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { loadResourcesAsync, selectResources } from "../app/reducers/resource";
import {
  DataSource,
  KFold,
  Model,
  Prediction,
  Recipe,
  ResourceType,
} from "../app/types";

interface CreatorProps {
  open: boolean;
  onClose: () => void;
}

type ActionMap = {
  [key in Action]: ResourceType;
};

const actionMap: ActionMap = {
  upload: "DATA_SOURCE",
  kfold: "K_FOLD",
  predict: "PREDICTION",
  train: "MODEL",
  confirm: "NONE",
  none: "NONE",
};

export default function ResourceCreator(props: CreatorProps) {
  const [action, setAction] = useState<Action>("none");
  const [steps, setSteps] = useState<Action[]>(["none"]);
  const [stepIndex, setStepIndex] = useState(0);
  const [dataSource, setDataSource] = useState<DataSource>();
  const [model, setModel] = useState<Model>();
  const [prediction, setPrediction] = useState<Prediction>();
  const [kFold, setKFold] = useState<KFold>();
  const dispatch = useAppDispatch();
  const resources = useAppSelector(selectResources);
  const recipe: Recipe = {
    type: actionMap[action],
    data_source: dataSource,
    k_fold: kFold,
    model: model,
    prediction: prediction,
  };

  function reset() {
    setAction("none");
    setSteps(["none"]);
    setStepIndex(0);
    setDataSource(undefined);
    setModel(undefined);
    setPrediction(undefined);
    setKFold(undefined);
  }

  function onActionSelected(action: Action) {
    setAction(action);
    if (action === "upload") {
      setSteps(["upload", "confirm"]);
    } else if (action === "train") {
      setSteps(["upload", "train", "confirm"]);
    } else if (action === "predict") {
      setSteps(["upload", "train", "confirm"]);
    } else if (action === "kfold") {
      setSteps(["upload", "kfold", "confirm"]);
    } else {
      setSteps(["none"]);
    }
  }

  function onCreateData(source: DataSource) {
    setDataSource(source);
    if (stepIndex < steps.length) {
      setStepIndex(stepIndex + 1);
    }
  }

  function onCreateModel(model: Model) {
    setModel(model);
    if (stepIndex < steps.length) {
      setStepIndex(stepIndex + 1);
    }
  }

  function onCreatePrediction(prediction: Prediction) {
    setPrediction(prediction);
    if (stepIndex < steps.length) {
      setStepIndex(stepIndex + 1);
    }
  }

  function onCreateKFold(kFold: KFold) {
    setKFold(kFold);
    if (stepIndex < steps.length) {
      setStepIndex(stepIndex + 1);
    }
  }

  function onClose() {
    reset();
    props.onClose();
  }

  function onConfirm() {
    createResource(recipe, resources)
      .then(() => console.log("Created"))
      .catch(() => console.log("Not created"))
      .finally(() => {
        onClose();
        dispatch(loadResourcesAsync());
      });
  }

  const dataSelection = action !== "upload";
  const modelSelection = action === "predict";
  return (
    <CenterModal
      open={props.open}
      onClose={() => {
        reset();
        props.onClose();
      }}
    >
      {action === "none" && (
        <SelectAction onActionSelected={onActionSelected} />
      )}
      {steps[stepIndex] === "upload" && (
        <CreateData allowSelection={dataSelection} onComplete={onCreateData} />
      )}
      {steps[stepIndex] === "train" && (
        <CreateModel
          allowSelection={modelSelection}
          selectionOnly={modelSelection}
          onComplete={onCreateModel}
        />
      )}
      {steps[stepIndex] === "kfold" && (
        <CreateKFold onComplete={onCreateKFold} />
      )}
      {steps[stepIndex] === "confirm" && (
        <Confirm recipe={recipe} onSubmit={onConfirm} />
      )}
    </CenterModal>
  );
}
