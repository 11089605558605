import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";

import CenterModal from "./CenterModal";
import styles from "./ResourceTable.module.css";
import { deleteResource, download } from "../api/resource";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectResources,
  setResources,
  loadResourcesAsync,
} from "../app/reducers/resource";
import { Resource } from "../app/types";

export default function ResourceTable() {
  const dispatch = useAppDispatch();
  const resources = useAppSelector(selectResources);
  const [metadata, setMetadata] = useState("");
  const [showMetadata, setShowMetadata] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(loadResourcesAsync());
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const gridColumns: GridColDef[] = [
    {
      field: "status",
      headerName: "status",
      width: 120,
      valueGetter: (params) => params.value.code,
      cellClassName: (params) => {
        const code = params.row.status.code.toLowerCase();
        return styles[`row-${code}`];
      },
    },
    { field: "id", headerName: "id", width: 60 },
    { field: "type", headerName: "type", width: 175 },
    { field: "name", headerName: "name", width: 250 },
    {
      field: "timestamp",
      headerName: "date created",
      width: 250,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString();
      },
    },
    {
      field: "last_updated",
      headerName: "last updated",
      width: 250,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString();
      },
    },
  ];

  function onShowMetadata() {
    const resource = resources.find((res) => res.id === selectedRow);
    if (!resource) {
      return;
    }

    function shortenHeaders(headers: string[]): (string | "...")[] {
      if (headers.length > 3) {
        return [...headers.slice(0, 3), "..."];
      } else {
        return headers;
      }
    }

    const copy = JSON.parse(JSON.stringify(resource));

    let headers = copy["recipe"]["_headers"];
    if (headers) {
      headers = shortenHeaders(headers);
      copy["recipe"]["_headers"] = headers;
    }

    const metadata = JSON.stringify(
      { message: copy["status"]["message"], ...copy["recipe"] },
      null,
      2
    );
    setMetadata(metadata);
    setShowMetadata(true);
    handleContextMenuClose();
  }

  function onCloseModal() {
    setShowMetadata(false);
    setMetadata("");
    setShowDelete(false);
    setDeleteId(-1);
  }

  function onDelete(id: number | undefined) {
    if (!id) {
      return;
    }
    setDeleteId(id);
    setShowDelete(true);
    handleContextMenuClose();
  }

  function completeDelete() {
    deleteResource(deleteId)
      .then((success) => {
        if (!success) {
          // TODO: show error modal
          return;
        }
        const newResources = resources.filter(
          (value: Resource) => value.id !== deleteId
        );
        dispatch(setResources(newResources));
      })
      .catch((err) => {
        // TODO: show error modal
      })
      .finally(() => onCloseModal());
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const downloadFile = (id: number, endpoint: "download" | "explain") => {
    download(id, endpoint);
    handleContextMenuClose();
  };

  const allowDownload = resources.find(
    (res) =>
      res.id === selectedRow &&
      ["PREDICTION", "K_FOLD"].includes(res.type) &&
      res.status["code"] === "READY"
  );

  const allowExplainability = resources.find(
    (res) =>
      res.id === selectedRow &&
      res.type === "MODEL" &&
      res.status["code"] === "READY" &&
      res.recipe["metadata"]?.exp_key
  );

  const allowSweep = resources.find(
    (res) =>
      res.id === selectedRow &&
      res.type === "PREDICTION" &&
      res.status["code"] === "READY" &&
      res.recipe["metadata"]?.sweep_key
  );

  return (
    <div>
      <Paper elevation={4}>
        <DataGrid
          autoHeight
          rows={resources}
          columns={gridColumns}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          slotProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
        />
      </Paper>

      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleContextMenuClose();
            },
          },
        }}
      >
        <MenuItem onClick={onShowMetadata}>View metadata</MenuItem>
        {allowDownload && (
          <MenuItem onClick={() => download(selectedRow as number, "download")}>
            Download results
          </MenuItem>
        )}
        {allowExplainability && (
          <MenuItem onClick={() => download(selectedRow as number, "explain")}>
            Download explainability
          </MenuItem>
        )}
        {allowSweep && (
          <MenuItem onClick={() => download(selectedRow as number, "sweep")}>
            Download probability histogram
          </MenuItem>
        )}
        <MenuItem onClick={() => onDelete(selectedRow)}>Delete</MenuItem>
      </Menu>

      <CenterModal open={showMetadata} onClose={onCloseModal}>
        <pre>{metadata}</pre>
      </CenterModal>

      <CenterModal open={showDelete} onClose={onCloseModal}>
        <p>This will permanently delete the resource.</p>
        <p>
          Deleting a processing resource will not remove it from the cluster.
        </p>
        <Stack direction="row" justifyContent="right" spacing={2}>
          <Button variant="contained" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={completeDelete}>
            Delete
          </Button>
        </Stack>
      </CenterModal>
    </div>
  );
}
