import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import VerseonDark from "../res/logo-inline-dark.svg";
import VerseonLight from "../res/logo-inline-light.svg";

export default function Loader() {
  const theme = useTheme().palette.mode;
  const logo = theme === "dark" ? VerseonDark : VerseonLight;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Stack spacing={2}>
        <img src={logo} style={{ height: "50px", width: "auto" }} />
        <LinearProgress />
      </Stack>
    </div>
  );
}
