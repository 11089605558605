import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ReactNode, useState } from "react";

import { useAppSelector } from "../../app/hooks";
import { selectResources } from "../../app/reducers/resource";
import { Model, Resource } from "../../app/types";

interface CreateModelProps {
  allowSelection?: boolean;
  selectionOnly?: boolean;
  onComplete: (model: Model) => void;
}

export default function CreateModel(props: CreateModelProps) {
  const [select, setSelect] = useState(props.selectionOnly ? true : false);
  const [selectedId, setSelectedId] = useState("");
  const [ensembleWeights, setEnsembleWeights] = useState<Array<string>>([]);
  const [trainingWeights, setTrainingWeights] = useState<Array<string>>([]);
  const [nThreads, setNThreads] = useState(0);

  const resources = useAppSelector(selectResources).filter(
    (res: Resource) => res.type === "MODEL"
  );

  const menuItems = resources.map((res: Resource) => (
    <MenuItem key={res.id} value={res.id}>{`${res.id}: ${res.name}`}</MenuItem>
  ));

  function handleSelectChange(e: SelectChangeEvent, child?: ReactNode) {
    setSelectedId(e.target.value);
  }

  function onContinue() {
    if (select) {
      return props.onComplete(Number(selectedId));
    }

    const model: Model = {
      // TODO: look into debug & judge_training again
      debug: false,
      judge_training: false,
      ensemble_weights: ensembleWeights.map((x) => Number(x)),
      training_weights: trainingWeights.map((x) => Number(x)),
      n_threads: nThreads,
    };
    return props.onComplete(model);
  }

  function selector() {
    return (
      <div>
        <FormControl fullWidth size="small">
          <InputLabel>Model</InputLabel>
          <Select
            value={selectedId}
            onChange={handleSelectChange}
            label="model"
          >
            {menuItems}
          </Select>
        </FormControl>
      </div>
    );
  }

  function options() {
    return (
      <Stack>
        <TextField
          size="small"
          id="ensemble-weights"
          label="Ensemble Weights"
          value={ensembleWeights.toString()}
          onChange={(e) => setEnsembleWeights(e.target.value.split(","))}
          sx={{ marginTop: "8px" }}
        />
        <TextField
          size="small"
          id="training-weights"
          label="Training Weights"
          value={trainingWeights.toString()}
          onChange={(e) => setTrainingWeights(e.target.value.split(","))}
          sx={{ marginTop: "12px" }}
        />
        <TextField
          size="small"
          id="n-threads-override"
          label="Threading override"
          type="number"
          value={nThreads}
          onChange={(e) => {
            const num = Number(e.target.value);
            if (isNaN(num) || num < 0) {
              return;
            }
            setNThreads(num);
          }}
          sx={{ marginTop: "12px" }}
        />
      </Stack>
    );
  }

  return (
    <div>
      {!props.selectionOnly && props.allowSelection && menuItems.length > 0 && (
        <Button
          fullWidth
          variant="contained"
          endIcon={<SwapHorizIcon />}
          onClick={() => setSelect(!select)}
          sx={{ marginBottom: "12px" }}
        >
          {select ? "Create new model" : "Select existing model"}
        </Button>
      )}
      {select ? selector() : options()}
      <Button
        fullWidth
        variant="contained"
        color="success"
        onClick={onContinue}
        sx={{ marginTop: "12px" }}
      >
        Continue
      </Button>
    </div>
  );
}
