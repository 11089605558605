import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { loadResources } from "../../api/resource";
import { RootState } from "../store";
import { Resource } from "../types";

interface ResourceState {
  loading: boolean;
  error: boolean;
  resources: Resource[];
}

const initialState: ResourceState = {
  loading: true,
  error: false,
  resources: [],
};

export const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    setResources: (state, action: PayloadAction<Resource[]>) => {
      state.resources = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadResourcesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loadResourcesAsync.fulfilled, (state, action) => {
        state.resources = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(loadResourcesAsync.rejected, (state, action) => {
        state.resources = [];
        state.loading = false;
        state.error = true;
      });
  },
});

export const loadResourcesAsync = createAsyncThunk(
  "resource/loadResources",
  async () => {
    return await loadResources();
  }
);

export const { setResources } = resourceSlice.actions;

export const selectResources = (state: RootState) => state.resource.resources;

export default resourceSlice.reducer;
