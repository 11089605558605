import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import authReducer from "./reducers/auth";
import resourceReducer from "./reducers/resource";
import themeReducer from "./reducers/theme";
import counterReducer from "../features/counter/counterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    resource: resourceReducer,
    theme: themeReducer,
    counter: counterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
