import { Recipe, Resource } from "../app/types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export async function loadResources(): Promise<Resource[]> {
  const url = `${baseUrl}/v2/resource`;
  try {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const resources = await res.json();
    return Promise.resolve(resources);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteResource(id: number): Promise<boolean> {
  const url = `${baseUrl}/v2/resource/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      credentials: "include",
    });
    return Promise.resolve(res.status === 200);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createDataSource(
  recipe: Recipe,
  resources: Resource[] = []
): Promise<Resource> {
  const source = recipe.data_source;
  if (!source) {
    return Promise.reject();
  }

  if (typeof source === "number") {
    const resource = resources.find((res) => res.id === source);
    if (resource) {
      return Promise.resolve(resource);
    } else {
      return Promise.reject();
    }
  }

  let body;
  let headers = {};
  if (source.file) {
    body = new FormData();
    body.append("file", source.file);
    body.append("regression", source.regression.toString());
    body.append("headers", source.headers.toString());
    body.append("sample_ids", source.sample_ids.toString());
    body.append("name", source.name);
  } else {
    body = JSON.stringify({
      recipe: {
        regression: source.regression,
        headers: source.headers,
        sample_ids: source.sample_ids,
        remote_url: source.remote_url,
        name: source.name,
      },
    });
    headers = {
      "Content-Type": "application/json",
    };
  }

  const url = `${baseUrl}/v2/resource?type=DATA_SOURCE`;
  try {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: body,
      headers: headers,
    });
    return Promise.resolve(await res.json());
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createKFold(
  recipe: Recipe,
  resources: Resource[] = []
): Promise<Resource> {
  const kFold = recipe.k_fold;
  if (!kFold) {
    return Promise.reject();
  }

  const ds = await createDataSource(recipe, resources);
  const url = `${baseUrl}/v2/resource?type=K_FOLD`;
  try {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        recipe: {
          k: kFold.k,
          stratified: kFold.stratified,
          data_source_id: ds.id,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(await res.json());
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createModel(
  recipe: Recipe,
  resources: Resource[] = []
): Promise<Resource> {
  const model = recipe.model;
  if (!model) {
    return Promise.reject();
  }

  if (typeof model === "number") {
    const resource = resources.find((res) => res.id === model);
    if (resource) {
      return Promise.resolve(resource);
    } else {
      return Promise.reject(null);
    }
  }

  const ds = await createDataSource(recipe, resources);
  const url = `${baseUrl}/v2/resource?type=MODEL`;
  try {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        recipe: {
          debug: model.debug,
          judge_training: model.judge_training,
          data_source_id: ds.id,
          ensemble_weights: model.ensemble_weights,
          training_weights: model.training_weights,
          n_threads: model.n_threads,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(await res.json());
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createPrediction(
  recipe: Recipe,
  resources: Resource[] = []
): Promise<Resource> {
  const ds = await createDataSource(recipe, resources);
  const model = await createModel(recipe, resources);
  const url = `${baseUrl}/v2/resource?type=PREDICTION`;
  try {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        recipe: {
          data_source_id: ds.id,
          model_id: model.id,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(await res.json());
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createResource(
  recipe: Recipe,
  resources?: Resource[]
): Promise<boolean> {
  switch (recipe.type) {
    case "DATA_SOURCE":
      return new Promise((resolve) => {
        createDataSource(recipe, resources)
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    case "K_FOLD":
      console.log("Creating kFold");
      return new Promise((resolve) => {
        createKFold(recipe, resources)
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    case "MODEL":
      return new Promise((resolve) => {
        createModel(recipe, resources)
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    case "PREDICTION":
      return new Promise((resolve) => {
        createPrediction(recipe, resources)
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    default:
      return Promise.reject(null);
  }
}

export async function download(
  id: number,
  endpoint: "explain" | "download" | "sweep"
) {
  const url = `${baseUrl}/v2/resource/${id}/${endpoint}`;
  fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then(async (res) => {
      const link = (await res.json())["url"];
      window.open(link);
    })
    .catch((err) => {
      console.log(err);
    });
}
