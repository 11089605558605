import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { deepmerge } from "@mui/utils";
import { ReactNode } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface CenterModalProps {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  sx?: SxProps;
}

const CenterModal = (props: CenterModalProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={deepmerge(modalStyle, props.sx)}>{props.children}</Box>
    </Modal>
  );
};

export default CenterModal;
