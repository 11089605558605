import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { verify } from "../../api/auth";
import { RootState } from "../store";
import { AuthStatus } from "../types";

interface AuthState {
  status: AuthStatus;
}

const initialState: AuthState = {
  status: "maybe",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAsync.pending, (state) => {
        state.status = "maybe";
      })
      .addCase(verifyAsync.fulfilled, (state, action) => {
        state.status = action.payload;
      })
      .addCase(verifyAsync.rejected, (state) => {
        state.status = "no";
      });
  },
});

export const verifyAsync = createAsyncThunk("auth/verifyUser", async () => {
  return await verify();
});

export const { setAuthStatus } = authSlice.actions;

export const selectAuthStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;
