import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectAuthStatus, verifyAsync } from "./app/reducers/auth";
import { loadResourcesAsync } from "./app/reducers/resource";
import { selectTheme } from "./app/reducers/theme";
import "./App.css";
import Loader from "./components/Loader";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";

export default function App() {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectTheme);
  const authStatus = useAppSelector(selectAuthStatus);

  useEffect(() => {
    dispatch(verifyAsync()).then((action) => {
      if (action.payload === "yes") {
        dispatch(loadResourcesAsync());
      }
    });
  }, [dispatch]);

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  const showLoader = authStatus === "maybe";
  const showLogin = authStatus === "no";
  const component = showLoader ? <Loader /> : showLogin ? <Login /> : <Home />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component}
    </ThemeProvider>
  );
}
